import { Dialog, DialogActions, DialogContent, DialogTitle, Link } from "@mui/material";
import React from "react";
import WrappedButton from "../components/WrappedButton";
import { FormLine } from "./FormLine";
import { initialize } from "../components/fontawesome";

interface SigProps {
	onChange: Function;
	fl: FormLine;
}

export default function SignatureLine(props: SigProps) {
	const [showDialog, setShowDialog] = React.useState(false);
	const canvasRef = React.useRef<HTMLCanvasElement>(null);
	const [initialized, setInitialized] = React.useState(false)
	let mouseDown = false;

	let oldPos = null as any;

	const getPos = (e: MouseEvent) => {
		const pos = { x: 0, y: 0 };

		pos.x = e.offsetX;
		pos.y = e.offsetY;

		return pos;
	};

	const getTouchPos = (e: TouchEvent) => {
		const pos = { x: 0, y: 0 };

		if (e.touches.length === 1) {
			const touch = e.touches[0];
			pos.x = touch.pageX - (touch.target as any).offsetLeft;
			pos.y = touch.clientY - (touch.target as any).offsetTop;
		}

		return pos;
	};

	const draw = (newPos: any) => {
		if (!mouseDown) return;

		const cont = canvasRef.current?.getContext("2d");
		if (cont) {
			const pos = newPos;
			const lastPos = oldPos ?? pos;
			cont.lineCap = "round";
			cont.strokeStyle = "rgba(0,0,0,1)";
			cont.beginPath();
			cont.moveTo(lastPos.x, lastPos.y);
			cont.lineTo(pos.x, pos.y);
			cont.lineWidth = 3;
			cont.stroke();
			cont.closePath();
		}
	};

	const save = () => {
		const c = canvasRef.current;
		let base = c?.toDataURL("image/png");
		if (!base) return;

		base = base.substring(22);

		fetch("form/SaveSignature", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			cache: "no-cache",
			body: JSON.stringify({ data: base }),
		})
			.then((x) => x.json())
			.then((x) => {
				props.fl.value = x;
				props.fl.altValue = c?.toDataURL("image/png");
				props.onChange();
				setShowDialog(false);
			});
	};

	const clear = () => {
		if (canvasRef.current) {
			const cont = canvasRef.current.getContext("2d") as any;
			cont?.clearRect(0, 0, canvasRef.current.clientWidth, canvasRef.current.clientHeight);
			// Set background to white
			cont.fillStyle = "white";
			cont?.fillRect(0, 0, canvasRef.current.clientWidth, canvasRef.current.clientHeight);
		}
	};

	const init = () => {
		mouseDown = true;
		if (canvasRef.current) {
			if (!initialized) {
				const cont = canvasRef.current.getContext("2d") as any;
				cont?.clearRect(0, 0, canvasRef.current.clientWidth, canvasRef.current.clientHeight);
				// Set background to white
				cont.fillStyle = "white";
				cont?.fillRect(0, 0, canvasRef.current.clientWidth, canvasRef.current.clientHeight);
				setInitialized(true);
			}
			canvasRef.current.onmousedown = (e) => {
				oldPos = getPos(e);
				mouseDown = true;
			};

			canvasRef.current.ontouchstart = (e) => {
				oldPos = getTouchPos(e);
				mouseDown = true;
			};

			window.onorientationchange = clear;

			window.onresize = () => {};

			canvasRef.current.onmousemove = (e) => {
				draw(getPos(e));
				oldPos = getPos(e);
			};

			canvasRef.current.ontouchmove = (e) => {
				draw(getTouchPos(e));
				oldPos = getTouchPos(e);
			};

			window.onmouseup = () => {
				mouseDown = false;
			};

			window.ontouchend = () => {
				mouseDown = false;
			};
		}
		return false;
	};

	return (
		<>
			<Dialog fullWidth={true} fullScreen={true} open={showDialog}>
				<DialogTitle sx={{ display: "flex" }}>Signature Capture</DialogTitle>
				<DialogContent sx={{ paddingTop: "15px", overflowX: "hidden" }}>
					<canvas ref={canvasRef} onTouchStart={init} onMouseDown={init} width={580} height={100} style={{ border: "1px solid black", borderRadius: "8px" }}></canvas>
				</DialogContent>
				<DialogActions>
					<WrappedButton
						onClick={() => {
							setShowDialog(false);
						}}
						size="small"
						caption="Close"></WrappedButton>
					<WrappedButton onClick={clear} size="small" caption="Clear"></WrappedButton>
					<WrappedButton onClick={save} size="small" caption="Save"></WrappedButton>
				</DialogActions>
			</Dialog>
			{!props.fl.value && <Link onClick={() => setShowDialog(true)}>Click to Capture Signature</Link>}
			{props.fl.value && <img onClick={() => setShowDialog(true)} alt="Click to Capture Signature" src={"Picture/" + props.fl.value}></img>}
		</>
	);
}
