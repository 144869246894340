import { Button, Checkbox, FormControl, MenuItem, Select, TableCell, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { getLocalSetting, saveManyForm, SettingsEnum } from "../Util";
import { classes } from "./AttendanceClient";
import { AttendanceDetail } from "./AttendanceDetail";
import { getAttendanceSaveForm } from "./AttendanceInAndOut";
import { parseBoolean } from "./FormScripts";
import { PlacementDisruption } from "./PlacementDisruption";

interface FieldsProps {
	att: AttendanceDetail;
	PdReasons: Array<PlacementDisruption>;
	validateEndTime: Function;
	updateParent: Function;
	Refresh: Function;
	GetData: Function;
}

export function AttFields(props: FieldsProps) {
	const x = props.att;
	const autoSave = parseBoolean(getLocalSetting(SettingsEnum.AutoSaveAttendance));
	const [isCheckedIn, setIsCheckedIn] = React.useState(x.IsCheckedIn);
	const [inTime, setInTime] = React.useState(x.NewStartTime ?? x.StartTime);
	const [outTime, setOutTime] = React.useState(x.NewCheckOutTime ?? x.CheckOutTime);
	const [isCheckedOut, setIsCheckedOut] = React.useState(x.IsCheckedOut);
	const [aReason, setAReason] = React.useState(x.AbsentReason);
	const [isMarkedAbsent, setIsMarkedAbsent] = React.useState(x.IsAbsent ? "Unmark Absent" : "Mark Absent");

	const save = () => {
		if (!autoSave) {
			props.Refresh();
			props.updateParent();
			return;
		}

		fireSave();
	};

	const fireSave = async () => {
		let formsToSave: Array<any> = [] as Array<any>;
		const form = getAttendanceSaveForm(x);
		formsToSave.push(form);
		await saveManyForm(formsToSave);
		await props.GetData();
		props.Refresh();
	};

	const setAbsentReason = (val: string) => {
		x.AbsentReason = val;
		setAReason(val);
		save();
	};

	const toggleCheckin = () => {
		x.CheckIn();
		setInTime(x.NewStartTime as Date);
		setIsCheckedIn(x.IsCheckedIn);
		x.UpdateAddServiceButton && x.UpdateAddServiceButton();
		save();
	};

	const changeTime = (date: Date) => {
		if (!props.validateEndTime(date, x.CheckOutTime, x)) {
			alert("This attendance overlaps with another record.");
			return;
		}

		x.NewStartTime = date;
		setInTime(x.NewStartTime);
		save();
	};

	const toggleCheckOut = () => {
		x.CheckOut();
		setIsCheckedOut(x.IsCheckedOut);
		setOutTime(x.NewCheckOutTime ?? x.CheckOutTime);
		save();
	};

	const changeCheckoutTime = (date: Date) => {
		if (!props.validateEndTime(x.NewStartTime, date, x)) {
			alert("This attendance overlaps with another record.");
			return;
		}
		x.NewCheckOutTime = date;
		setOutTime(date);
		save();
	};

	const toggleAbsent = () => {
		x.MarkAbsent();
		setIsMarkedAbsent(x.IsAbsent ? "Unmark Absent" : "Mark Absent");
		props.Refresh();
		props.updateParent();
	};

	return (
		<>
			{x.IsAbsent ? (
				<TableCell style={classes.smallCell} colSpan={2}>
					<FormControl style={{ maxWidth: "100%", width: "100%" }}>
						<Select
							size="small"
							disabled={!x.IsAbsent}
							variant="standard"
							style={{ display: "block", fontSize: "14px" }}
							value={aReason}
							onChange={(event: any) => {
								setAbsentReason(event.target.value);
							}}
							displayEmpty>
							{props.PdReasons.map((pd) => (
								<MenuItem key={pd.EventDefinitionID} value={pd.EventDefinitionID}>
									{pd.EventName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</TableCell>
			) : (
				<>
					<TableCell style={classes.smallCell}>
						<Checkbox checked={isCheckedIn} onClick={() => toggleCheckin()} style={{ padding: "9px 0px" }} />
					</TableCell>
					<TableCell style={classes.smallCell}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<TimePicker
								InputAdornmentProps={{
									style: { margin: "0px" },
								}}
								views={["hours", "minutes"]}
								disabled={!isCheckedIn}
								value={inTime}
								onChange={(date: any) => changeTime(date)}
								renderInput={(params) => <TextField size="small" variant="standard" fullWidth={true} {...params} />}></TimePicker>
						</LocalizationProvider>
					</TableCell>
				</>
			)}
			{isCheckedIn && !x.IsAbsent ? (
				<>
					<TableCell style={classes.smallCell}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<TimePicker
								InputAdornmentProps={{
									style: { margin: "0px" },
								}}
								disabled={!x.IsCheckedOut}
								views={["hours", "minutes"]}
								value={outTime}
								onChange={(date: any) => changeCheckoutTime(date)}
								renderInput={(params) => <TextField size="small" variant="standard" fullWidth={true} {...params} />}></TimePicker>
						</LocalizationProvider>
					</TableCell>
					<TableCell style={classes.smallCell}>
						<Checkbox disabled={!isCheckedIn} checked={isCheckedOut} onClick={() => toggleCheckOut()} style={{ padding: "9px 0px" }} />
					</TableCell>
				</>
			) : !x.IsAdditionalAttendance ? (
				<TableCell style={classes.smallCell} sx={{ textAlign: "center" }} colSpan={2}>
					<Button
						style={{ padding: "4px 6px", minWidth: "100px" }}
						onClick={() => {
							toggleAbsent();
						}}
						variant="contained"
						color="primary">
						{isMarkedAbsent}
					</Button>
				</TableCell>
			) : (
				<></>
			)}
		</>
	);
}
