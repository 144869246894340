import { FormRequest } from "../forms/FormRequest";
import FormRender from "./FormRender";
import { getQueryParam, parseBoolean } from "./FormScripts";

export default function Landing() {
	//https://localhost:44429/landing?key_value=new&events2do_id=&event_id=87a80c9a-0738-48b9-9492-5ca200f4ac4f&form_header_id=c7d86092-f80d-40f6-a9ae-57fc5e26938b&is_complete_sched_event=false&parent_value=7890F64D-5AD0-4B3D-BABA-ED37A1D28B24&form_mode=ADD&peID=898ac8ab-8c47-4028-98e9-4015d1292c5b&programPS=dbb437b1-dcd7-4c82-8ed6-192bb07faa61&sitePS=67f46a08-2698-4d1c-a20e-5f069ad37915
	const fp: FormRequest = {
		EventID: getQueryParam("event_id"),
		Events2DoId: getQueryParam("events2do_id"),
		FormHeaderId: getQueryParam("form_header_id"),
		KeyValue: getQueryParam("key_value"),
		isCompleteScheduledEvent: parseBoolean(getQueryParam("is_complete_sched_event")) || parseBoolean(getQueryParam("completeSchedEvent")),
		ParentValue: getQueryParam("parent_value"),
		FormMode: getQueryParam("form_mode"),
		ProgramPS: getQueryParam("programPS"),
		SitePS: getQueryParam("sitePS"),
		ProgramEnrollment: getQueryParam("peID") || getQueryParam("programEnrollment"),
		IsAddAllowed: true,
		IsEditAllowed: true,
		ServiceTrack: getQueryParam("serviceTrack"),
	};

	return (
		<div style={{ paddingTop: "15px", height: "100%" }}>
			<FormRender req={fp} isSubForm={false}></FormRender>
		</div>
	);
}
