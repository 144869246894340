import React, { ReactElement } from "react";
import { FormLine, LookupObject } from "./FormLine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import ReactQuill from "react-quill";
import LUTModal from "../components/LUTModal";

import { isNullOrUndefined } from "../Util";
import { FormatDataByTypeCode, GetDefaultStaffTS, getDataValueTS } from "../components/FormScripts";

interface MemoControlProps {
	fl: FormLine;
	captionComponent: ReactElement;
	checkChange: Function;
	val: string;
	isDisabled: boolean;
}

export default function MemoControl(props: MemoControlProps) {
	const fl = props.fl;
	const [showLut, setShowLut] = React.useState(false);

	let column = "";
	let dt = "";
	if (fl.textTemplate) {
		if (Array.isArray(fl.textTemplate) && fl.textTemplate.length > 0) {
			const tt = fl.textTemplate[0];
			column = tt.column_name;
			dt = tt.data_table;
		} else {
			column = fl.textTemplate.column_name;
			dt = fl.textTemplate.data_table;
		}
	}
	const textTemplateLut = (a: any, b: any, c: any) => {
		if (isNullOrUndefined(column) || isNullOrUndefined(dt)) {
			alert("No text templates are available.");
			return;
		}
		setShowLut(true);
	};

	const lutInfo = {
		lut_code: "text_templates",
	} as LookupObject;

	const onClose = (res: any) => {
		setShowLut(false);

		const text = getDataValueTS("text_template_view", "text_template_id", res.code_field, "template_text");
		fl.value = fl.value ? fl.value + text : text;
		props.checkChange();
	};

	const addTimestamp = () => {
		let text = FormatDataByTypeCode(new Date().toISOString(), "DT") + " - ";

		const w = window as any;
		if (typeof w.currentStaffDescription === "undefined") {
			w.currentStaffDescription = getDataValueTS("staff_view", "staff_id", GetDefaultStaffTS(), "staff_description");
		}
		text += w.currentStaffDescription;
		fl.value += " " + text;
		props.checkChange();
	};

	return (
		<>
			{showLut && <LUTModal lutValue={lutInfo} open={true} onClose={onClose} Parameter1={column} Parameter2={dt} Parameter3={(window as any).getCurrentAgency()} />}
			{props.captionComponent}
			<div id={"toolbar-" + fl.columnName}>
				<button className="ql-bold"></button>
				<button className="ql-italic"></button>
				<button className="ql-underline"></button>
				<button className="ql-list" value="ordered"></button>
				<button className="ql-list" value="bullet"></button>
				<button className="ql-clean"></button>
				<button className="ql-texttemplates">
					<FontAwesomeIcon icon={findIconDefinition({ prefix: "fas", iconName: "note-sticky" })} fontWeight={400} />
				</button>
				<button className="ql-timestamp" onClick={addTimestamp}>
					<FontAwesomeIcon icon={findIconDefinition({ prefix: "fas", iconName: "stopwatch" })} fontWeight={400} />
				</button>
			</div>
			<ReactQuill
				modules={{
					toolbar: {
						container: "#toolbar-" + fl.columnName,
						handlers: {
							texttemplates: textTemplateLut,
							// timestamp: addTimestamp,
						},
					},
				}}
				style={{ width: "100%", display: "block", height: "150px", marginBottom: "50px" }}
				theme="snow"
				value={props.val}
				onChange={(val: string) => {
					fl.value = val;
				}}
				readOnly={!fl.isModifiable || props.isDisabled}
				onBlur={() => {
					props.checkChange();
				}}
			/>
		</>
	);
}
