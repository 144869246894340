import { Table, TableCell, TableHead, TableBody, TableRow, Checkbox } from "@mui/material";
import React from "react";
import { FacilityContext } from "../App";
import { dateToWcf, FormatDataByTypeCode, parseBoolean } from "./FormScripts";

interface GroupMember {
	people_id: string;
	schedules_id: string;
	client_name: string;
	is_checked_in: string;
	profile_name: string;
	event_name: string;
	time_from: string;
}

export default function GroupCheckIn() {
	let currentFacility = React.useContext(FacilityContext);
	const [memberData, setMemberData] = React.useState([] as Array<GroupMember>);
	React.useEffect(() => {
		getData();
	}, [currentFacility]);

	const getData = () => {
		fetch("Attendance/GetGroupCheckIn")
			.then((dt) => dt.json())
			.then((dataSet: any) => {
				setMemberData(dataSet.map((x: any) => x as GroupMember));
			});
	};

	const checkInClient = (x: GroupMember) => {
		fetch("Attendance/CheckGroupMemberIn", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			cache: "no-cache",
			body: JSON.stringify({
				schedulesId: x.schedules_id,
				peopleId: x.people_id,
				date: dateToWcf(new Date().toLocaleDateString()),
			}),
		}).then((x) => getData());
	};

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Group</TableCell>
					<TableCell>Client</TableCell>
					<TableCell>Service</TableCell>
					<TableCell>Start Time</TableCell>
					<TableCell>Check In</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{memberData.map((x: GroupMember) => {
					return (
						<TableRow>
							<TableCell>{x.profile_name}</TableCell>
							<TableCell>{x.client_name}</TableCell>
							<TableCell>{x.event_name}</TableCell>
							<TableCell>{FormatDataByTypeCode(x.time_from, "TM")}</TableCell>
							<TableCell>
								<Checkbox checked={parseBoolean(x.is_checked_in)} disabled={parseBoolean(x.is_checked_in)} onClick={() => checkInClient(x)} style={{ padding: "9px 0px" }} />
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
}
