import React from "react";
import WrappedButton from "../WrappedButton";

export default function ExcelFileImport() {
	const upload = React.useRef(null);

	const uploadExcel = (event: any) => {
		const file = (event?.target as any).files[0];
		console.log(file);
	};

	return (
		<>
			<input onChange={uploadExcel} hidden ref={upload} type="file"></input>
			<WrappedButton caption="Upload File" onClick={() => (upload.current as any).click()}></WrappedButton>
		</>
	);
}
