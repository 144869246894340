import * as React from "react";

import { Button, CircularProgress, SxProps } from "@mui/material";

interface Props {
	onClick: Function;
	isBusy?: boolean;
	caption: string;
	className?: string;
	size?: "small" | "large" | "medium" | undefined;
	sx?: SxProps | undefined;
	isDisabled?: boolean;
}

export default function WrappedButton(props: Props) {
	const [isBusy, setIsBusy] = React.useState(false);
	const onClick = async () => {
		setIsBusy(true);
		props.onClick();
		setIsBusy(false);
	};

	let busy = isBusy || props.isBusy;

	const style = Object.assign(props.sx ?? {}, { marginLeft: "4px" });

	return (
		<Button size={props.size ?? "large"} sx={style} disabled={busy || props.isDisabled === true} className={props.className ?? ""} onClick={onClick} variant="contained" color="primary">
			{props.caption}
			{busy && <CircularProgress size={20}></CircularProgress>}
		</Button>
	);
}
