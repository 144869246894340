import { CircularProgress } from "@mui/material";
import { FacilityContext } from "../App";

import React from "react";
import { FormatDataByTypeCode } from "./FormScripts";
import { WidgetGroupBy } from "./WidgetComponents/WidgetGroupBy";
import { WidgetTable } from "./WidgetComponents/WidgetTable";

interface WidgetProps {
	widgetCode: string;
	widgetName: string;
	programCode: string;
}

export interface WidgetColumn {
	Caption: string;
	ColumnName: string;
	ColumnOrder: number;
	IsCustomColumn: boolean;
	IsGroupedColumn: boolean;
	IsListed: boolean;
	TypeCode: string;
	IsPrimaryKey: boolean;
}

export default function Widget(props: WidgetProps) {
	let currentFacility = React.useContext(FacilityContext);
	const [config, setConfig] = React.useState([] as Array<WidgetColumn>);
	const [data, setData] = React.useState([] as Array<any>);
	const [load, setLoad] = React.useState(false);
	React.useEffect(() => {
		fetch("widget/GetWidgetConfig", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			cache: "no-cache",
			body: JSON.stringify(props.widgetCode),
		})
			.then((x) => x.json())
			.then((x) => {
				const parsed = JSON.parse(x.GetWidgetConfigResult);
				setConfig(Object.keys(parsed).map((x) => parsed[x] as WidgetColumn));
			});
		getData();
		setProgram(props.programCode);
		// eslint-disable-next-line
	}, [currentFacility]);

	const setProgram = async (prog: string) => {
		await fetch("widget/SetWidgetProgram", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			cache: "no-cache",
			body: JSON.stringify(prog),
		});
	};

	const getData = () => {
		setLoad(true);
		fetch("widget/GetWidgetData", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			cache: "no-cache",
			body: JSON.stringify(props.widgetCode),
		})
			.then((x) => {
				if (!x.ok) alert("An error occurred, please refresh the page.");
				return x.json();
			})
			.then((x) => {
				const j = JSON.parse(x.GetWidgetDataResult);
				console.log(j);
				setData(j);
				setLoad(false);
			});
	};

	const grouped = config.filter((x) => x.IsGroupedColumn);
	let isGroupedWidget = false;

	const groupBys = {} as any;
	if (grouped.length) {
		isGroupedWidget = true;

		const groupBy = grouped[0];

		data.forEach((x) => {
			const groupByValue = x[groupBy.ColumnName];
			if (!Object.keys(groupBys).includes(groupByValue)) {
				groupBys[groupByValue] = [];
			}

			groupBys[groupByValue].push(x);
		});
	}

	return (
		<>
			{!load &&
				(!isGroupedWidget ? (
					<WidgetTable Columns={config} Refresh={getData} Data={data} />
				) : (
					<>
						{Object.keys(groupBys).map((x) => {
							return <WidgetGroupBy key={x} Display={FormatDataByTypeCode(x, grouped[0].TypeCode)} Data={groupBys[x]} Refresh={getData} Columns={config} />;
						})}
					</>
				))}
			{load && (
				<div style={{ padding: "15px", alignItems: "center", flexDirection: "column", display: "flex" }}>
					<CircularProgress size={40}></CircularProgress>
				</div>
			)}
		</>
	);
}
