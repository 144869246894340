import { Grid, Typography, TextField, Checkbox, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import React from "react";
import { FormLine } from "./FormLine";

interface AssessmentProps {
	fl: FormLine;
}

export interface TestQuestion {
	caption: string;
	details_type_code: string;
	is_group: boolean;
	test_setup_details_id: string;
	Answers: Array<TestAnswer>;
	GetValue: Function;
	is_required: boolean;
	test_details_id: string;
}

export interface TestAnswer {
	test_setup_answers_caption: string;
	test_setup_details_answers_id: string;
	value: any;
	test_details_id: string;
	test_details_answers_id: string;
}

export function AssessmentRender(props: AssessmentProps) {
	const questions = props.fl.testValue.Questions;
	return (
		<>
			{questions.map((x: TestQuestion, idx: number) => {
				if (x.is_group) {
					return (
						<Grid item key={idx} xs={12} sx={{ borderBottom: "1px solid #ebebeb", paddingLeft: 1, paddingTop: 1, paddingBottom: 1, marginBottom: 2 }}>
							<Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
								{x.caption}
							</Typography>
						</Grid>
					);
				}

				return <QuestionRender q={x} key={idx}></QuestionRender>;
			})}
		</>
	);
}

interface QuestionProps {
	q: TestQuestion;
}

function QuestionRender(props: QuestionProps) {
	const x = props.q;
	const modified = false;
	const [narValue, setNarValue] = React.useState(x.Answers[0].value);
	const [radioValue, setRadioValue] = React.useState("");
	const [multiValue, setMultiValue] = React.useState({} as any);
	const [numValue, setNumValue] = React.useState(x.Answers[0].value);

	x.GetValue = () => {
		switch (x.details_type_code) {
			case "NARRATIVE":
				return narValue;
			case "SINGLELIST":
				return radioValue;
			case "MULTILIST":
				return multiValue;
			case "NUMERIC_SCORE":
				return numValue;
		}
	};

	let captionComponent = (
		<>
			<Typography color={modified ? "primary" : ""} variant="subtitle1" display={"block"}>
				{x.caption + (x.is_required ? "*" : "")}
			</Typography>
		</>
	);

	let answerElement = <></>;
	switch (x.details_type_code) {
		case "SINGLELIST":
			answerElement = (
				<RadioGroup sx={{ marginLeft: "20px" }} name={x.test_setup_details_id}>
					{x.Answers.map((y, idx) => {
						return <FormControlLabel key={idx} value={y.test_setup_details_answers_id} onChange={(event) => setRadioValue((event as any).currentTarget.value)} label={y.test_setup_answers_caption} control={<Radio size="small"></Radio>}></FormControlLabel>;
					})}
				</RadioGroup>
			);
			break;
		case "NARRATIVE":
			answerElement = <TextField value={narValue} sx={{ marginTop: "8px" }} fullWidth onChange={(event: any) => setNarValue(event?.target.value)} rows={5} multiline label={x.Answers.length ? x.Answers[0].test_setup_answers_caption || x.caption : x.caption}></TextField>;
			if (!(x.Answers.length ? x.Answers[0].test_setup_answers_caption || x.caption : x.caption)) captionComponent = <></>;
			break;
		case "MULTILIST":
			answerElement = (
				<>
					{x.Answers.map((y, idx) => (
						<FormControlLabel
							sx={{ marginLeft: "8px" }}
							key={idx}
							style={{ display: "block" }}
							value={y.test_setup_details_answers_id}
							onChange={(event) => {
								const curVal = multiValue;
								if (curVal.hasOwnProperty(y.test_setup_details_answers_id)) delete curVal[y.test_setup_details_answers_id];
								else curVal[y.test_setup_details_answers_id] = "";
								setMultiValue(curVal);
							}}
							label={y.test_setup_answers_caption}
							control={<Checkbox size="small"></Checkbox>}></FormControlLabel>
					))}
				</>
			);
			break;
		case "NUMERIC_SCORE":
			answerElement = <TextField type="number" sx={{ marginTop: "12px", display: "block" }} size="small" value={numValue} label={x.Answers.length ? x.Answers[0].test_setup_answers_caption || x.caption : x.caption} onChange={(event: any) => setNumValue(event?.target.value)}></TextField>;
			captionComponent = <></>;
			break;
		default:
			console.log(x);
	}

	return (
		<>
			{captionComponent}
			{answerElement}
		</>
	);
}
