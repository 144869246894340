import { List, ListItem, Typography } from "@mui/material";
import React from "react";
import { debounce } from "lodash";

interface WheelPickerProps {
	values: Array<any>;
	selected: number;
	caption: string;
	setValue: Function;
	setCallback: Function;
}

export default function WheelPicker(props: WheelPickerProps) {
	const [selected, setSelected] = React.useState(props.selected);
	const listRef = React.useRef<HTMLDivElement>(null);
	const refs = [] as Array<React.RefObject<any>>;
	for (var i = 0; i < props.values.length; i++) {
		refs[i] = React.createRef();
	}
	React.useEffect(() => {
		centerScroll();
		bindScroll();

		// eslint-disable-next-line
	}, [selected]);

	props.setCallback(() => selected);

	const centerScroll = debounce(() => {
		const e = refs[selected].current;

		if (!e) return;
		const scrolling = e.offsetParent.parentElement;
		// console.log(e.offsetTop);
		scrolling.scrollTo({ top: e.offsetTop - (scrolling.clientHeight / 2 - 15) });
	}, 500);

	// if (forceScroll > -1) window.clearTimeout(forceScroll);
	// forceScroll = window.setTimeout(() => {
	// 	const e = refs[selected].current;

	// 	const scrolling = e.offsetParent.parentElement;
	// 	// console.log(e.offsetTop);
	// 	scrolling.scrollTo({ top: e.offsetTop - (scrolling.clientHeight / 2 - 15) });
	// }, 1000);

	const bindScroll = () => {
		if (listRef.current)
			listRef.current.onscroll = () => {
				if (listRef.current) {
					const compare = listRef.current?.scrollTop + listRef.current?.clientHeight / 2;
					for (var i = 0; i < refs.length; i++) {
						const c = refs[i].current;
						if (typeof c === "undefined") continue;
						if (c === null) continue;
						if (c.offsetTop < compare && c.offsetTop + c.clientHeight > compare) {
							setSelected(i);
							break;
						}
					}
				}

				centerScroll();
			};
	};

	return (
		<div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
			<Typography color={""} variant="subtitle1" display="block">
				{props.caption}
			</Typography>
			<div ref={listRef} style={{ overflowY: "auto" }}>
				<List>
					{props.values.map((x: any, i: number) => (
						<ListItem ref={refs[i]} onClick={() => setSelected(i)} selected={selected === i} sx={{ color: selected === i ? "black" : "#ebebeb", marginTop: i === 0 ? "40px" : "0px", marginBottom: i === props.values.length - 1 ? "40px" : "0px" }}>
							{x}
						</ListItem>
					))}
				</List>
			</div>
		</div>
	);
}
