import { DialogTitle, Dialog, DialogContent } from "@mui/material";
import WrappedButton from "./WrappedButton";
import { FormObject } from "../forms/FormObject";
import { FormRequest } from "../forms/FormRequest";
import FormRender from "./FormRender";
import { cloneObject } from "./FormScripts";
interface FormProps {
	form?: FormObject;
	req?: FormRequest;
	open: boolean;
	onClose: Function;
	isSubForm: boolean;
}

export default function FormModal(props: FormProps) {
	let form = null as any;
	if (props.form) {
		form = new FormObject(cloneObject(props.form));
		if (form.formMode === "NEW" && props.isSubForm) form.formMode = "ADD";
	}

	return (
		<Dialog maxWidth={"xl"} fullWidth={true} open={props.open}>
			<DialogTitle sx={{ display: "flex" }}>
				{props.form ? props.form.formName : props.req?.FormName}
				<div style={{ display: "flex", flex: "1", justifyContent: "flex-end" }}>
					<WrappedButton size="small" onClick={() => props.onClose(form)} isBusy={false} caption="Save"></WrappedButton>
					<WrappedButton size="small" onClick={() => props.onClose()} isBusy={false} caption="Close"></WrappedButton>
				</div>
			</DialogTitle>
			<DialogContent sx={{ paddingTop: "15px" }}>
				<FormRender req={props.req} form={form} isSubForm={props.isSubForm}></FormRender>
			</DialogContent>
		</Dialog>
	);
}
