import React from "react";
import { Route, useHistory } from "react-router";
import { Layout } from "./components/Layout";

import "./custom.css";
import Login from "./components/Login";
import Landing from "./components/Landing";
import { AttendanceInAndOut } from "./components/AttendanceInAndOut";
import * as fontawesome from "./components//fontawesome";
import Widget from "./components/Widget";
import GroupCheckIn from "./components/GroupCheckIn";
import MainMenu from "./components/MainMenu";
import Kiosk from "./components/Kiosk";
import { isNullOrUndefined } from "./Util";
import ExcelFileImport from "./components/ProviderPortal/ExcelFileImport";

export const FacilityContext = React.createContext("");
fontawesome.initialize();

const timeout = 30;

export let lastDate = new Date().getTime();

window.actualFetch = fetch;
window.fetch = async (...args) => {
	touchTimeout();
	return await window.actualFetch(...args);
}

export function touchTimeout() {
	lastDate = new Date().getTime();
}

window.setInterval(async () => {
	const n = new Date().getTime();
	if ((n - lastDate) / (1000 * 60) >= timeout) {
		console.log("Session timeout");
		await fetch("login/Logout");
		window.document.location.reload();
	}
}, 5000);

export async function getNavigation() {
	let res = [];
	await fetch("organization/GetWidgetNav").then(x => x.json()).then(x => res = x);
	let access = {};
	await fetch("organization/GetModuleAccess").then(x => x.json()).then(x => access = x);
	const navs = [];

	if (access.attendance) {
		navs.push({
			path: "/attend",
			widgetCode: "",
			display: "Attendance",
			isWidget: false
		});
	}
	// navs.push({
	// 	path: "/check-in-kiosk",
	// 	widgetCode: "",
	// 	display: "Kiosk",
	// 	isWidget: false,
	// 	suppressDisplay: true
	// });

	res.forEach(x => {
		navs.push({
			path: "/" + x.widget_code,
			widgetCode: x.widget_code,
			display: x.navigation_display,
			isWidget: true,
			programCode: x.program_code,
			navigationOverride: x.navigation_override,
			suppressFacilityDropdown: x.suppress_facility_dropdown,
			order: x.widget_order || 0
		});
	});
	return navs;
}

export default function App() {
	const [authenticated, setAuthenticated] = React.useState(false);
	const [activeFacility, setActiveFacility] = React.useState("");
	const [moduleNavs, setModuleNavs] = React.useState([]);
	const [path, setPath] = React.useState(document.location.pathname);

	const history = useHistory();
	history.listen(() => {
		setPath(document.location.pathname);
	})

	const toggleAuth = () => {
		setAuthenticated(true);
		getNavigation().then(x => {
			setModuleNavs(x)
		});
	}

	let p = document.location.pathname;
	p = path === "/" ? "/attendance" : path;
	const foundMod = moduleNavs.find(x => x.path === p)
	return (
		<>
			{authenticated && (
				<FacilityContext.Provider value={activeFacility}>
					<Layout changeFacility={setActiveFacility} navText={!foundMod || foundMod.suppressDisplay ? "" : moduleNavs.find(x => x.path === p).display}>
						<Route exact path="/" component={AttendanceInAndOut} />
						<Route exact path="/attend" component={AttendanceInAndOut} />
						<Route exact path="/check-in-kiosk" component={Kiosk} />
						<Route exact path="/groupcheckin" component={GroupCheckIn} />
						<Route exact path="/mainmenu" component={MainMenu} />
						<Route exact path="/providerportal" component={ExcelFileImport} />
						{moduleNavs.map(x => {
							let comp = null;
							if (x.isWidget && isNullOrUndefined(x.navigationOverride)) {
								comp = <Widget widgetName={x.display} widgetCode={x.widgetCode} programCode={x.programCode}></Widget>
							}

							return <Route key={x.path} exact path={x.path} render={() => comp}></Route>
						})}
						<Route path="/landing" component={Landing} />
					</Layout>
				</FacilityContext.Provider>
			)}
			{!authenticated && <Login setAuthenticated={toggleAuth}></Login>}
		</>
	);
}
