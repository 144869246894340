import { Table, TableRow, TableHead, TableCell, TableBody } from "@mui/material";
import { WidgetColumn } from "../Widget";
import { isNullOrUndefined, openForm } from "../../Util";
import { FormatDataByTypeCode } from "../FormScripts";

interface WidgetTableProps {
	Columns: Array<WidgetColumn>;
	Data: Array<any>;
	Refresh: Function;
	IsGrouped?: boolean;
}

export function WidgetTable(props: WidgetTableProps) {
	const config = props.Columns;

	const visColumns = config
		.filter((x) => x.IsListed && !x.IsCustomColumn)
		.sort((a, b) => {
			return a.ColumnOrder - b.ColumnOrder;
		});
	const getPrimaryKey = (row: any) => {
		const pk = config.find((x) => x.IsPrimaryKey);

		if (!pk) return "new";

		return row[pk.ColumnName];
	};

	const launchFormFromWidgetRow = async (row: any) => {
		const kv = getPrimaryKey(row);
		const pv = row.is_people ? row.people_id : row.units_id || row.group_profile_id || row.people_id;
		const win = await openForm(
			row.event_form_header_id || row.form_header_id, //
			kv,
			"EDIT",
			row.event_definition_id,
			row.program_responsible || row.program_providing_service,
			row.site_providing_service,
			pv,
			isNullOrUndefined(row.event_log_id),
			row.program_enrollment_event_id,
			row.service_track_event_id,
			row.units_id
		);

		const interval = setInterval(() => {
			if (win?.closed) {
				props.Refresh();
				clearInterval(interval);
			}
		}, 2000);
	};
	return (
		<Table size="small" sx={{ border: props.IsGrouped ? "1px solid #ebebeb" : "" }}>
			<TableHead>
				<TableRow>
					{/* <TableCell></TableCell> */}
					{visColumns.map((x) => {
						return <TableCell key={x.ColumnName}>{x.Caption}</TableCell>;
					})}
				</TableRow>
			</TableHead>
			<TableBody>
				{props.Data.map((x: any, idx: number) => {
					return (
						<TableRow
							key={idx}
							onClick={() => {
								launchFormFromWidgetRow(x);
							}}
							sx={{ backgroundColor: idx % 2 === 0 ? "white" : "#f2f2f2" }}>
							{visColumns.map((y: WidgetColumn, idx: number) => {
								return <TableCell sx={{ padding: "2px 4px", verticalAlign: "top" }} dangerouslySetInnerHTML={{ __html: FormatDataByTypeCode(x[y.ColumnName], y.TypeCode) }} key={y.ColumnName}></TableCell>;
							})}
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
}
