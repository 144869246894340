import React from "react";
import KioskStage2 from "./KioskStages/KioskStage2";

export default function Kiosk() {
	return (
		<>
			{/* {stage === 0 && (
				<KioskStage1
					stage2={(searchParameters: any) => {
						setStage(1);
						setSearchParameters(searchParameters);
					}}
				/>
			)} */}
			{<KioskStage2 searchParams={{}} resetKiosk={() => document.location.reload()} />}
		</>
	);
}
