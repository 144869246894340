import { Typography, Paper } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { Container } from 'reactstrap';
import { isNullOrUndefined } from '../Util';
import { FormatDataByTypeCode } from './FormScripts';
import NavMenu from './NavMenu';

export var changePath;

export function Layout(props) {
  const [path, setPath] = React.useState(document.location.pathname.toLowerCase())
  changePath = (newPath) => {
    setPath(newPath)
  }

  const history = useHistory();

  history.listen(() => {
    changePath(document.location.pathname.toLocaleLowerCase());
  });

  React.useEffect(() => {
    setPath(document.location.pathname.toLowerCase());
  }, [])

  const shouldShowNav = !path.includes("/landing") && !path.includes("/mainmenu") && !path.includes("/check-in-kiosk");
  return (
    <div style={{ height: "100%" }}>
      {shouldShowNav && <NavMenu changeFacility={props.changeFacility} />}
      <Container style={{ height: "calc(100% - 42px)" }}>
        <Paper sx={{ padding: 2, height: "100%", overflowY: "auto" }}>
          {!isNullOrUndefined(props.navText) &&
            <Typography variant="h6">{props.navText + " - " + FormatDataByTypeCode(new Date(), "D")}</Typography>
          }
          {props.children}
        </Paper>
      </Container>
    </div>
  );

}
