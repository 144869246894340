import React from "react";
import FormRender from "../FormRender";
import { FormRequest } from "../../forms/FormRequest";
import { getDataValueTS, isNullOrUndefined, setFormElement } from "../FormScripts";

interface KioskStage2Props {
	searchParams: any;
	resetKiosk: Function;
}

export default function KioskStage2(props: KioskStage2Props) {
	const setSearchVals = () => {
		const s = props.searchParams;
		setFormElement("client_first_name", s.firstName);
		setFormElement("client_last_name", s.lastName);
		setFormElement("gender_id", s.genderId);
		setFormElement("dob", s.dob);
	};
	const KioskEventId = "cf05736e-e9ed-400b-a85a-98a88a34d5f8";

	const fp: FormRequest = {
		EventID: KioskEventId,
		FormHeaderId: getDataValueTS("event_definition", "event_definition_id", KioskEventId, "form_header_id_new"),
		KeyValue: "NEW",
		isCompleteScheduledEvent: false,
		ParentValue: "",
		FormMode: "ADD",
		ProgramPS: "",
		SitePS: "",
		ProgramEnrollment: "",
		IsAddAllowed: true,
		IsEditAllowed: true,
		ServiceTrack: "",
	};
	return (
		<FormRender
			beforeCloseFunction={() => {
				document.location.reload();
			}}
			req={fp}
			isSubForm={false}
			afterLoadFunction={setSearchVals}
			afterSaveFunction={() => {
				const msg = getDataValueTS("event_definition", "event_definition_id", KioskEventId, "description");
				if (!isNullOrUndefined(msg)) {
					alert(msg);
				}
				props.resetKiosk();
			}}
		/>
	);
}
