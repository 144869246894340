export function isNullOrUndefined(val: any): boolean {
	return (window as any).isNullOrUndefined(val);
}

export async function saveManyForm(form: Array<any>): Promise<boolean> {
	await fetch("form/SaveManyForm", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		cache: "no-cache",
		body: JSON.stringify(form),
	}).then(x => x.json())
		.then(x => {
			if (x.isError) {
				alert("There was an error during save: \n\n" + x.messages.join(","));
			}
		});
	return false;
}

export function createForm(mode: string, keyValue: string, dataTable: string) {
	let form: any = {
		FormLines: [] as Array<any>, //
		formMode: mode,
		keyValue: keyValue,
		dataTable: dataTable,
		primaryKey: dataTable + "_id",
		parentColumn: "",
		parentValue: "",
	};

	return form;
}

export function createFormLine(column: string, value: any, typeCode: string = "S") {
	return {
		ddColumnsId: "new123",
		columnName: column,
		value: value,
		typeCode: typeCode,
		isDirty: true,
		useEventLog: false,
	};
}

export function guidGenerator(): string {
	var S4 = function () {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	};
	return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
}

export function getLocalSetting(name: SettingsEnum): string | null {
	return localStorage.getItem(name);
}

export function setLocalSetting(name: SettingsEnum, val: string) {
	localStorage.setItem(name, val);
}

export enum SettingsEnum {
	CheckInDefaultCurrentTime = "check-in-default-now",
	CheckOutDefaultCurrentTime = "check-out-default-now",
	AutoSaveAttendance = "auto-save-attendance",
}

export function buildUrl(base: string, params: any) {
	let url = base;
	if (!url.includes("?")) {
		url += "?";
	}

	Object.keys(params).forEach((x: string, idx: number) => {
		if (idx !== 0) url += "&";

		url += x + "=" + params[x];
	});

	return url;
}

export async function getFormUrl() {
	if (isNullOrUndefined((window as any).formAppUrl)) {
		const res = await fetch("login/FormURL");
		let baseUrl = await res.text();

		if (!baseUrl.endsWith("landing")) {
			baseUrl += baseUrl.endsWith("/") ? "" : "/";
			baseUrl += "landing";
		}

		if (!baseUrl.startsWith("https://")) {
			baseUrl = "https://" + baseUrl;
		}
		(window as any).formAppUrl = baseUrl;
	}

	return (window as any).formAppUrl;
}

export async function openForm(
	formId: string, //
	keyValue: string,
	formMode: string,
	eventDef: string,
	programPs: string,
	sitePs: string,
	parentValue: string,
	completeSchedEvent: boolean = false,
	peId: string = "",
	stId: string = "",
	unitId: string = ""
) {
	const base = await getFormUrl();

	const url = buildUrl(base, {
		event_id: eventDef,
		parent_value: parentValue,
		form_header_id: formId,
		key_value: keyValue || "NEW",
		form_mode: formMode || "ADD",
		sitePS: sitePs,
		programPS: programPs,
		serviceTrack: stId,
		programEnrollment: peId,
		unitsId: unitId,
		completeSchedEvent: completeSchedEvent,
	});

	return window.open(url, "_blank");
}
