import React from "react";
import { Box, Paper, TextField, Typography, Checkbox } from "@mui/material";
import WrappedButton from "./WrappedButton";
import { useHistory } from "react-router";
import { getQueryParam } from "./FormScripts";

interface LoginProps {
	setAuthenticated: Function;
}

export default function Login(props: LoginProps) {
	const [user, setUser] = React.useState("");
	const [pass, setPass] = React.useState("");
	const [isSaving, setSaving] = React.useState(false);
	const [hasError, setHasError] = React.useState(false);
	const [useAd, setUseAd] = React.useState(false);
	const errMsg = getQueryParam("login_error");

	const [useSso, setUseSso] = React.useState(false);

	const history = useHistory();

	const checkKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if ((event as any).which === 13) {
			login();
		}
	};
	React.useEffect(() => {
		fetch("login/IsLoggedIn")
			.then((dt) => dt.json())
			.then((dt) => {
				if (dt) props.setAuthenticated(true);
				else
					fetch("login/UseSSO")
						.then((dt) => dt.json())
						.then((dt) => {
							if (dt) {
								setUseSso(true);
								// fetch("login/SSOAuthenticated")
								// 	.then((dt) => dt.json())
								// 	.then((dt) => {
								// 		if (!dt) document.location.href = "/login/login";
								// 	});
							}
						});
			});
		// eslint-disable-next-line
	}, []);

	const sso = () => {
		document.location.href = "/login/login?user=" + user;
	};

	const login = async () => {
		setSaving(true);
		let dt: any = await fetch("login/Authenticate", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			cache: "no-cache",
			body: JSON.stringify({ username: user, password: pass, tzo: -(new Date("1/1/2009").getTimezoneOffset() / 60), useAd: useAd }),
		});
		if (dt.ok) {
			var res = await dt.json();
			if (res) {
				props.setAuthenticated();
				history.push("/mainmenu");
			} else {
				setHasError(true);
			}
		} else {
			setHasError(true);
		}
		setSaving(false);
	};

	return (
		<Box sx={{ height: 1, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundImage: "url('auth-background.jpg')", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundColor: "background.default" }}>
			<Paper sx={{ p: 2, flexDirection: "column", display: "flex" }}>
				<img style={{ maxWidth: "250px", marginBottom: "16px" }} src="logo-2.png" alt=""></img>
				<TextField
					label="Username"
					variant="outlined"
					value={user}
					onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => checkKeyUp(event)}
					sx={{ marginBottom: 2 }}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setUser(event.target.value);
					}}></TextField>
				<TextField
					label="Password"
					type="password"
					variant="outlined"
					value={pass}
					onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => checkKeyUp(event)}
					// className={props.classes.loginInput}
					sx={{ marginBottom: 2 }}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setPass(event.target.value);
					}}></TextField>
				<Typography variant="body1" color={"error"} hidden={!hasError && !errMsg}>
					{errMsg ?? "Invalid username or password."}
				</Typography>
				<Typography style={{ textAlign: "start" }} variant="body2">
					Use Active Directory?
				</Typography>
				<Checkbox style={{ width: "0%", padding: "0px 10px 5px" }} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUseAd(event.target.checked)}></Checkbox>
				<Box sx={{ display: "flex" }}>
					{useSso && <WrappedButton isBusy={isSaving} caption={"SSO Login"} onClick={sso}></WrappedButton>}
					<WrappedButton isBusy={isSaving} caption="Evolv Login" onClick={login}></WrappedButton>
				</Box>
			</Paper>
			<Typography variant="body1" sx={{ fontSize: "12px", fontWeight: 600, position: "absolute", bottom: 0 }}>
				Powered By Ilmir, Inc.
			</Typography>
		</Box>
	);
}
