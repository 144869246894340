import * as React from "react";
import "./NavMenu.css";
import { FormControl, Select, MenuItem, Checkbox } from "@mui/material";
import { Facility } from "../interfaces/Facilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useHistory } from "react-router";
import SettingsDialog from "./SettingsDialog";
import { getNavigation } from "../App";
import { isNullOrUndefined } from "../Util";

const classes = {
	formControl: {
		width: "100%",
	},
	breadcrumbBar: {
		padding: "2px 4px",
	},
	breadcrumbItem: {
		textDecoration: "underline",
	},
	popOverMenu: {
		position: "absolute",
		top: "0px",
		bottom: "0px",
		right: "0px",
		margin: "2px",
		left: "0px",
		transition: ".5s",
		zIndex: 999,
		display: "block",
	},
	popOverMenuVisible: {
		"& > div": {
			display: "block !important",
		},
	},
	popOverContent: {
		border: "1px solid #ebebeb",
		width: "70%",
		height: "100%",
		backgroundColor: "white",
		padding: "0px 4px",
		position: "relative",
	},
	popOverItem: {
		padding: "4px 6px",
		borderBottom: "1px solid #ebebeb",
		display: "flex",
		alignItems: "center",
		fontSize: "24px",
		overflowX: "hidden",
	},
} as any;

interface Props {
	changeFacility: Function;
}

interface ModuleNav {
	path: string;
	widgetCode: string;
	display: string;
	isWidget: boolean;
	suppressFacilityDropdown: boolean;
}

export default function NavMenu(props: Props) {
	const [isPopoutVisible, setPopoutVisible] = React.useState(false);
	const history = useHistory();
	const [facilities, setFacilities] = React.useState([] as Array<Facility>);
	const [currentFacility, setCurrentFacility] = React.useState("");
	const [showSettings, setShowSettings] = React.useState(false);
	const [displayNav, setDisplayNav] = React.useState([] as Array<ModuleNav>);
	const [path, setPath] = React.useState("");
	const [showAllfacilities, setShowAllFacilities] = React.useState(false);
	const [hideDropdown, setHideDropdown] = React.useState(false);

	React.useEffect(() => {
		getNavigation().then((x) => {
			setDisplayNav(x as Array<ModuleNav>);
			nav(document.location.pathname);
		});
		console.log(path);
		// eslint-disable-next-line
	}, []);

	const getFacilities = (url: string, forceSelect: boolean = false) => {
		fetch(url)
			.then((dt) => dt.json())
			.then((facils: any) => {
				let facs: Array<Facility> = [];
				let currentFacility2: string = "";
				facils.forEach((fac: any) => {
					let f: Facility = {
						Name: fac.profile_name,
						GroupProfileID: fac.group_profile_id,
						UnitDescription: showAllfacilities ? "" : fac.unit_description,
						UnitsID: showAllfacilities ? null : fac.units_id,
					};
					if (f.GroupProfileID !== currentFacility2 && !isNullOrUndefined(f.UnitsID) && !showAllfacilities && facils.filter((x: any) => x.group_profile_id === fac.group_profile_id).length > 1) {
						currentFacility2 = f.GroupProfileID;
						let f2: Facility = {
							Name: fac.profile_name,
							GroupProfileID: fac.group_profile_id,
							UnitDescription: "All",
							UnitsID: null,
						};
						facs.push(f2);
					}
					facs.push(f);
				});
				setFacilities(facs);

				if ((isNullOrUndefined(currentFacility) || forceSelect) && facs.length > 0) {
					selectFacility(facs[0]);
				}
			});
	};

	const handleChange = async (event: any) => {
		let selectedId: string = event.target.value.toLowerCase();
		let fac: Facility = facilities.filter((fac) => fac.UnitsID?.toLowerCase() === selectedId || fac.GroupProfileID.toLowerCase() === selectedId)[0];

		selectFacility(fac);
	};

	const selectFacility = async (fac?: Facility) => {
		await fetch("Facility/SetFacility", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			cache: "no-cache",
			body: JSON.stringify({ unitsId: fac?.UnitsID ?? null, groupProfileId: fac?.GroupProfileID ?? null }),
		});

		setCurrentFacility(fac?.UnitsID || fac?.GroupProfileID || "");
		props.changeFacility(fac);
	};

	const logout = async () => {
		await fetch("login/Logout");
		window.document.location.reload();
	};

	const nav = (p: string) => {
		history.push(p);
		setShowAllFacilities(false);
		setFacilities([]);
		const newPath = p === "" || p === "/" ? "attend" : p;
		setPath(newPath);
		window.setTimeout(() => {
			if (newPath === "/attend") {
				setPath("attend");
				getFacilities("Facility", true);
			} else if (newPath === "/groupcheckin" || newPath === "groupcheckin") {
				getFacilities("Facility/GetMyGroups", true);
			} else {
				getFacilities("Facility/GetServiceFacilities", true);
			}
		}, 3000);
	};

	const toggleFacilities = (val: boolean) => {
		setShowAllFacilities(val);
		const p = document.location.pathname;
		const newPath = p === "" || p === "/" ? "attend" : p;
		if (newPath.includes("attend")) {
			if (val) {
				getFacilities("Facility/GetAttendanceFacilities", true);
			} else {
				getFacilities("Facility", true);
			}
		} else {
			if (val) {
				getFacilities("Facility/GetProgramFacilities", true);
			} else {
				getFacilities("Facility/GetServiceFacilities", true);
			}
		}
	};

	const found = displayNav.find((x) => x.path === document.location.pathname);
	if (found && found.suppressFacilityDropdown && found.suppressFacilityDropdown !== hideDropdown) {
		setHideDropdown(found?.suppressFacilityDropdown ?? false);
		selectFacility();
	}

	if (document.location.pathname.includes("providerportal") && !hideDropdown) {
		setHideDropdown(true);
	}

	return (
		<header>
			<div style={{ width: "100%", padding: "5px", display: "flex" }}>
				<FontAwesomeIcon
					icon={findIconDefinition({ prefix: "fas", iconName: "house" })}
					onClick={() => {
						history.push("/mainmenu");
					}}
					style={{ fontSize: "24px", margin: "2px 8px 0px 2px" }}
				/>
				{!hideDropdown && (
					<FormControl variant="standard" sx={classes.formControl}>
						<Select placeholder="Select a Facility..." value={currentFacility} onChange={handleChange}>
							{facilities &&
								facilities.map((fac) => {
									return (
										<MenuItem key={fac.UnitsID || fac.GroupProfileID} value={fac.UnitsID || fac.GroupProfileID}>
											{fac.Name + (fac.UnitsID ? " - " + fac.UnitDescription : "")}
										</MenuItem>
									);
								})}
							{
								<MenuItem>
									<Checkbox
										sx={{ padding: 0, paddingRight: 1 }}
										value={showAllfacilities}
										checked={showAllfacilities}
										onChange={(event) => {
											toggleFacilities(event.target.checked);
										}}
									/>
									Show All Facilities
								</MenuItem>
							}
						</Select>
					</FormControl>
				)}
				<SettingsDialog hideDialog={() => setShowSettings(false)} isVisible={showSettings}></SettingsDialog>
			</div>
			<div
				onClick={() => {
					setPopoutVisible(false);
				}}
				hidden={!isPopoutVisible}
				style={classes.popOverMenu}>
				<div style={classes.popOverContent} className={`box-shadow`}>
					<div
						onClick={() => {
							setShowSettings(true);
						}}
						style={classes.popOverItem}>
						<span style={{ flex: 1 }}>Settings</span>
						<FontAwesomeIcon icon={findIconDefinition({ prefix: "fas", iconName: "cog" })} />
					</div>
					{displayNav.map((x) => {
						return (
							<div
								key={x.path}
								style={classes.popOverItem}
								onClick={() => {
									nav(x.path);
								}}>
								<span style={{ flex: 1 }}>{x.display}</span>
							</div>
						);
					})}
					<div onClick={() => logout()} style={classes.popOverItem}>
						<span style={{ flex: 1 }}>Logout</span>
						<FontAwesomeIcon icon={findIconDefinition({ prefix: "fas", iconName: "sign-out-alt" })} />
					</div>
				</div>
			</div>
		</header>
	);
}
