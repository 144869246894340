import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper } from "@mui/material";
import React from "react";
import { WidgetTable } from "./WidgetTable";
import { WidgetColumn } from "../Widget";

interface WidgetGroupByProps {
	Display: string;
	Data: Array<any>;
	Refresh: Function;
	Columns: Array<WidgetColumn>;
}

export function WidgetGroupBy(props: WidgetGroupByProps) {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<>
			<Paper onClick={() => setIsOpen(!isOpen)} sx={{ padding: 0.5, marginBottom: isOpen ? 0.5 : 1, cursor: "pointer" }}>
				<FontAwesomeIcon style={{ fontSize: "14px", marginRight: "8px", marginLeft: "8px" }} icon={["fas", isOpen ? "caret-down" : "caret-right"]}></FontAwesomeIcon>
				{props.Display}
			</Paper>
			{isOpen && (
				<div style={{ paddingLeft: "8px", marginBottom: "8px" }}>
					<WidgetTable IsGrouped={true} Data={props.Data} Refresh={props.Refresh} Columns={props.Columns}></WidgetTable>
				</div>
			)}
		</>
	);
}
