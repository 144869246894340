import { findIconDefinition, IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Paper, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import { getNavigation } from "../App";
import SettingsDialog from "./SettingsDialog";

interface ModuleNav {
	path: string;
	widgetCode: string;
	display: string;
	isWidget: boolean;
	navigationOverride: string;
	order: number;
}
export default function MainMenu() {
	const hist = useHistory();
	const [displayNav, setDisplayNav] = React.useState([] as Array<ModuleNav>);
	const [showSettings, setShowSettings] = React.useState(false);
	React.useEffect(() => {
		getNavigation().then((x) =>
			setDisplayNav(
				(x as Array<ModuleNav>).sort((a, b) => {
					const bo = b.order || 0;
					const ao = a.order || 0;
					if (ao === 0 && bo !== 0) {
						return -1;
					} else if (ao !== 0 && bo === 0) {
						return 1;
					} else if (ao !== 0 && bo !== 0) {
						return ao - bo;
					} else {
						return a.display.localeCompare(b.display);
					}
				})
			)
		);
	}, []);

	const getIcon = (iconClass: IconPrefix, iconName: IconName, caption: string, route: string) => {
		return (
			<Paper
				key={route}
				sx={{ cursor: "pointer", display: "flex", padding: 1, width: "auto", margin: 1, textAlign: "center", alignItems: "center" }}
				onClick={() => {
					hist.push(route);
				}}>
				<FontAwesomeIcon icon={findIconDefinition({ prefix: iconClass, iconName: iconName })} style={{ marginRight: "12px", fontSize: "24px" }} />
				<Typography variant="h6">{caption}</Typography>
			</Paper>
		);
	};

	const logout = async () => {
		await fetch("login/Logout");
		window.document.location.reload();
	};
	return (
		<div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div style={{ height: "100%", flexWrap: "wrap", flex: 1, overflowY: "auto" }}>
				<SettingsDialog hideDialog={() => setShowSettings(false)} isVisible={showSettings}></SettingsDialog>
				{displayNav.map((x) => {
					return getIcon("fas", "bookmark", x.display, x.navigationOverride || x.path);
				})}
			</div>
			<div>
				<ButtonGroup variant="contained" sx={{ width: 1, display: "flex" }}>
					<Button sx={{ flex: "1" }} onClick={() => setShowSettings(true)}>
						<FontAwesomeIcon icon={findIconDefinition({ prefix: "fas", iconName: "gears" })} style={{ fontSize: "20px", marginRight: 5 }}></FontAwesomeIcon>Settings
					</Button>
					<Button sx={{ flex: "1" }} onClick={logout}>
						<FontAwesomeIcon icon={findIconDefinition({ prefix: "fas", iconName: "arrow-right-from-bracket" })} style={{ fontSize: "20px", marginRight: 5 }}></FontAwesomeIcon>Logout
					</Button>
				</ButtonGroup>
			</div>
		</div>
	);
}
