import { getLocalSetting, guidGenerator, isNullOrUndefined, SettingsEnum } from "../Util";
import { parseBoolean } from "./FormScripts";

export class AttendanceDetail {
	Client: string = "";
	AttendanceDetailsID?: string;
	ID: string = guidGenerator();
	PeopleID: string = "";
	GroupProfileID: string = "";
	SchedulesID: string = "";
	EnrollmentID: string = "";
	StaffID: string = "";
	ProgramID: string = "";

	StartTime: Date = new Date();
	NewStartTime?: Date;

	CheckOutTime?: Date;
	NewCheckOutTime?: Date;
	OldCheckOut: boolean = false;

	AbsentReason?: string;
	OldAbsentReason?: string;
	IsAbsent: boolean = false;
	OldIsAbsent: boolean = false;

	HalfDay: number = 0;
	FullDayThreshold: number = 0;
	FullDay: number = 0;
	IsAdditionalAttendance: boolean = false;
	CanAddAtt: boolean = false;
	UpdateAddServiceButton?: Function;

	private _isCheckedOut: boolean = false;
	get IsCheckedOut(): boolean {
		return this._isCheckedOut;
	}

	MarkAbsent() {
		this.IsAbsent = !this.IsAbsent;
		if (!this.IsAbsent) this.AbsentReason = "";
	}

	CheckOut() {
		this._isCheckedOut = !this._isCheckedOut;
		if (this._isCheckedOut && parseBoolean(getLocalSetting(SettingsEnum.CheckOutDefaultCurrentTime))) {
			this.NewCheckOutTime = new Date();
		}
	}

	private _isCheckedIn: boolean = false;
	CheckIn() {
		this._isCheckedIn = !this._isCheckedIn;
		if (this._isCheckedIn && parseBoolean(getLocalSetting(SettingsEnum.CheckInDefaultCurrentTime))) {
			this.NewStartTime = new Date();
		} else if (this._isCheckedIn) {
			this.NewStartTime = this.StartTime;
		}
	}
	get IsCheckedIn(): boolean {
		return this._isCheckedIn;
	}

	get formMode(): string {
		if (!isNullOrUndefined(this.AttendanceDetailsID) && !this._isCheckedIn) return "DELETE";
		else if (isNullOrUndefined(this.AttendanceDetailsID) && this._isCheckedIn) return "ADD";
		else if (isNullOrUndefined(this.AttendanceDetailsID) && this.IsAbsent) return "ADD";
		else if (!isNullOrUndefined(this.AttendanceDetailsID) && !this.IsAbsent && this.OldIsAbsent) return "DELETE";
		else return "EDIT";
	}

	constructor(att: any) {
		this.Client = att.client_name;
		let dt: string = att.time_from;
		if (dt !== null && dt !== "") {
			this.StartTime = new Date(Date.parse(dt));
			this.NewStartTime = this.StartTime;
		}

		this.AttendanceDetailsID = att.attendance_details_id;
		this.EnrollmentID = att.event_log_id;
		this._isCheckedIn = !isNullOrUndefined(this.AttendanceDetailsID);
		this.PeopleID = att.people_id;
		this.GroupProfileID = att.group_profile_id;
		this.SchedulesID = att.schedules_id;
		this.StaffID = att.current_staff_id;
		this.ProgramID = att.program_info_id;
		this.CanAddAtt = parseBoolean(att.can_add_att);

		if (att.hasOwnProperty("is_checked_out")) {
			this._isCheckedOut = parseBoolean(att.is_checked_out.toString());
			this.OldCheckOut = this._isCheckedOut;
		}

		if (att.hasOwnProperty("la_definition_id")) {
			this.OldAbsentReason = att.la_definition_id;
			this.AbsentReason = this.OldAbsentReason;
			this.IsAbsent = !isNullOrUndefined(this.OldAbsentReason);
			this.OldIsAbsent = this.IsAbsent;
		}

		if (att.hasOwnProperty("time_to")) {
			this.CheckOutTime = new Date(Date.parse(att.time_to));
			this.NewCheckOutTime = this.CheckOutTime;
		}

		if (att.hasOwnProperty("half_day")) {
			this.HalfDay = parseInt(att.half_day);
			this.FullDay = parseInt(att.full_day);
			this.FullDayThreshold = parseInt(att.full_day_threshold);
		}
	}
}
