import { FormLine } from "./FormLine";
import { isNullOrUndefined } from "../Util";
import { FormatDataByTypeCode } from "../components/FormScripts";

export class FormObject {
	formName: string = "";
	formMode: string = "";
	keyValue: string = "";
	isLocked: boolean = false;
	signAllowed: boolean = false;
	hasLockAccess: boolean = false;
	FormLines: Array<FormLine> = [] as Array<FormLine>;
	[key: string]: any;

	constructor(p: any) {
		Object.keys(p).forEach((x) => {
			this[x] = p[x];
		});
	}

	getFormElement = (column: string) => {
		if (isNullOrUndefined(column)) return null;
		const fl = this.FormLines.find((x) => x.columnName?.toLowerCase() === column.toLowerCase());

		return fl?.getValue();
	};

	getFormDisplay = (column: string) => {
		if (isNullOrUndefined(column)) return null;

		const fl = this.FormLines.find((x) => x.columnName?.toLowerCase() === column.toLowerCase());
		if (!fl) return null;

		let res: string = "";
		switch (fl.typeCode) {
			case "FK":
				if (fl.lutValue?.value) {
					res = (fl.lutValue?.prompt ? fl.lutValue.prompt + " - " : "") + fl.lutValue?.description;
				}
				break;
			default:
				res = FormatDataByTypeCode(fl.value, fl.typeCode);
				break;
		}

		return res;
	};
}
