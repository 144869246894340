import { isNullOrUndefined } from "../Util";
import "react-quill/dist/quill.snow.css";
import { parseBoolean } from "../components/FormScripts";
import { FormObject } from "./FormObject";

export interface LookupField {
	field_label: string;
	field_length: number;
	field_name: string;
	field_order: number;
	is_listed: boolean;
	is_searched: boolean;
	type_code: string;
}

export interface LookupDefinition {
	fields: Array<LookupField>;
	lut_name: string;
	scrnval_id: string;
	is_explicit_search: boolean;
}

export interface LookupObject {
	description: string;
	depends_on: string;
	depends_on_column: string;
	depends_on_other: string;
	lut_code: string;
	scrnval_id: string;
	value: string;
	lutInfo: LookupDefinition;
	prompt: string;
}

export interface SubReportDefinition {
	columns: Array<any>;
	data_source_name: string;
	number_of_rows?: number;
	report_code: string;
	report_header_id: string;
	select_top_n: number;
	report_sql: string;
	subreport_name: string;
}

async function getDescriptionAndPrompt(lut?: string, fkey?: string): Promise<any> {
	if (!fkey || !lut) return null;

	let res = null;
	await fetch("form/GetDescriptionAndPrompt", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		cache: "no-cache",
		body: JSON.stringify({ lut: lut, fkey: fkey }),
	})
		.then((x) => x.json())
		.then((x) => {
			res = JSON.parse(x);
		});

	return res;
}

export function createFormLine(col: string, value: string, typeCode: string = "S", useEl: boolean = false, isUdf: boolean = false) {
	const f = new FormLine({});

	f.columnName = col;
	f.value = value;
	f.ddColumnsId = "123";
	f.useEventLog = useEl;
	f.typeCode = typeCode;
	f.userDefined = isUdf;
	f.isDirty = true;

	return f;
}

export class FormLine {
	caption: string = "";
	typeCode: string = "S";
	isGroupHeader: boolean = false;
	value: any = null;
	oldValue: any = null;
	isModifiable: boolean = false;
	disabled: boolean = false;
	isVisible: boolean = false;
	isRequired: boolean = false;
	lutValue?: LookupObject;
	columnName: string = "";
	[key: string]: any;
	srValue?: SubReportDefinition;
	forceSetValue?: Function;
	textTemplate: any;

	constructor(p: any) {
		Object.keys(p).forEach((x) => {
			this[x] = p[x];
		});

		if (this.isSubForm()) {
			(this as any).sfValue = this.sfValue.map((x: any) => {
				const f = new FormObject(x);
				f.FormLines = f.FormLines.map((x) => new FormLine(x));
				return f;
			});
		}
	}
	isSubForm = () => this.typeCode === "ESF" || this.typeCode === "SF";

	ReinitializeDrivingFormline = () => {};

	getValue = () => {
		if (this.isSubForm()) {
			return this.sfValue.filter((x: any) => x.formMode === "EDIT" || (x.FormLines.filter((y: any) => y.isDirty).length > 0 && x.formMode === "ADD"));
		}
		return this.lutValue?.value ?? this.value;
	};

	getOldValue = () => {
		if (this.typeCode === "SF") {
			return this.sfValue.filter((x: any) => x.formMode === "EDIT" || x.formMode === "DELETE");
		}

		return this.lutOldValue?.value ?? this.oldValue ?? this.sfOldValue;
	};

	setValue = async (val: any) => {
		switch (this.typeCode) {
			case "FK":
				(this.lutValue as any).value = val;
				break;
			case "L":
				this.value = parseBoolean(val);
				break;
			case "SF":
			case "ESF":
				this.sfValue = val;
				break;
			case "DT":
			case "D":
				this.value = val ?? null;
				break;
			default:
				this.value = val;
				break;
		}

		if (this.typeCode === "L") this.value = parseBoolean(this.value);

		if (this.typeCode === "FK") {
			const dp = await getDescriptionAndPrompt(this.lutValue?.scrnval_id, this.lutValue?.value);
			if (dp) {
				(this.lutValue as any).description = dp.description;
				(this.lutValue as any).prompt = dp.prompt;
				this.forceSetValue && this.forceSetValue(this.lutValue?.value);
			}
		} else if (this.typeCode === "SF" || this.typeCode === "ESF") this.forceSetValue && this.forceSetValue(this.sfValue);
		else this.forceSetValue && this.forceSetValue(this.value);

		this.isDirty = this.isModified();
	};

	isModified = () => {
		const newVal = this.getValue();
		const oldVal = this.getOldValue();
		if (isNullOrUndefined(oldVal) !== isNullOrUndefined(newVal)) return true;

		switch (this.typeCode) {
			case "TD":
			case "N":
			case "DEC2":
			case "INT":
				return parseInt(newVal) !== parseInt(oldVal);

			case "D":
			case "DT":
				return new Date(oldVal).getTime() !== new Date(newVal).getTime();
			case "ESF":
			case "SF":
				return newVal.filter((x: any) => !oldVal.find((y: any) => y === x)).length > 0;
			default:
				return newVal !== oldVal;
		}
	};
}
