import { DialogTitle, Dialog, DialogContent, TextField, TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody, InputAdornment, Grid, CircularProgress, Checkbox, Typography } from "@mui/material";
import { ChangeEvent } from "react";
import { LookupDefinition, LookupObject } from "../forms/FormLine";
import { isNullOrUndefined } from "../Util";
import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import WrappedButton from "./WrappedButton";
import { cloneObject, FormatDataByTypeCode } from "./FormScripts";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

interface LookupProps {
	lutValue?: LookupObject;
	open: boolean;
	onClose: Function;
	Parameter1?: string;
	Parameter2?: string;
	Parameter3?: string;
	Parameter4?: string;
	Parameter5?: string;
}

export function evalDependsOn(lutRequest: any, lutInfo?: LookupObject) {
	if (!lutInfo) return;

	lutRequest.Parameter1 = (window as any).getFormElement(lutInfo?.depends_on_column);

	const other = lutInfo?.depends_on_other?.split("~");
	other?.forEach((x: string, idx: number) => {
		try {
			// eslint-disable-next-line
			(lutRequest as any)["Parameter" + (idx + 2).toString()] = eval(x);
		} catch (e: any) {
			alert("Error evaling depends on other: " + x + " exception: " + e.toString());
		}
	});
}

export async function getLutConfig(lutCode: string, callback: Function) {
	fetch("form/GetLutConfig", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		cache: "no-cache",
		body: JSON.stringify(lutCode),
	})
		.then((x) => x.json())
		.then((x) => {
			const res = JSON.parse(x);
			console.log(res);
			callback(res);
		});
}

export default function LUTModal(props: LookupProps) {
	const [resultSet, setResultSet] = React.useState([] as Array<any>);
	const [load, setLoad] = React.useState(false);
	const [searchFields, setSearchFields] = React.useState({} as any);
	const [lutInfo, setLutInfo] = React.useState(props.lutValue?.lutInfo);

	React.useEffect(() => {
		lutLoad(); // eslint-disable-next-line
	}, [props.open]);

	const lutLoad = () => {
		if (!props.lutValue || !lutInfo) return;
		if (props.open && props.lutValue && !props.lutValue.lutInfo.is_explicit_search) {
			query();
		}
	};

	if (isNullOrUndefined(lutInfo))
		getLutConfig(props.lutValue?.lut_code as string, (lu: LookupDefinition) => {
			setLutInfo(lu);
			lutLoad();
		});

	if (isNullOrUndefined(props.lutValue) || isNullOrUndefined(lutInfo)) return <></>;
	const filteredFields = lutInfo?.fields.filter((x) => x.is_searched) || [];

	if (Object.keys(searchFields).length === 0) {
		filteredFields.forEach((x) => {
			searchFields[x.field_name] = null;
		});
		setSearchFields(searchFields);
	}

	const changeSearchFields = (field: string, value: any) => {
		const sf = cloneObject(searchFields);
		sf[field] = value;
		setSearchFields(sf);
		query(sf);
	};

	const query = (sf?: any) => {
		setLoad(true);
		if (load) return;
		const lutRequest = {
			Code: lutInfo?.scrnval_id,
			WhereClause: [] as Array<any>,
			Parameter1: (window as any).getFormElement(props.lutValue?.depends_on_column),
			Parameter2: "" as string,
			Parameter3: "" as string,
			Parameter4: "" as string,
			Parameter5: "" as string,
		};

		if (!isNullOrUndefined(props.Parameter1) && isNullOrUndefined(lutRequest.Parameter1)) {
			lutRequest.Parameter1 = props.Parameter1;
		}

		lutRequest.Parameter2 = props.Parameter2 || "";
		lutRequest.Parameter3 = props.Parameter3 || "";

		if (isNullOrUndefined(sf)) sf = searchFields;

		evalDependsOn(lutRequest, props.lutValue);

		Object.keys(sf).forEach((col) => {
			const s = filteredFields.find((x) => x.field_name === col);
			lutRequest.WhereClause.push({ Column: col, Value: sf[col], Type: s?.type_code });
		});

		fetch("form/GetLutData", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			cache: "no-cache",
			body: JSON.stringify(lutRequest),
		})
			.then((x) => x.json())
			.then((x) => {
				const res = JSON.parse(x) as Array<any>;
				setResultSet(res);
				setLoad(false);
			});
	};

	return (
		<Dialog maxWidth={"xl"} fullWidth={true} open={props.open}>
			<DialogTitle sx={{ display: "flex" }}>
				{lutInfo?.lut_name}
				<div style={{ display: "flex", flex: "1", justifyContent: "flex-end" }}>
					<WrappedButton size="small" onClick={() => props.onClose(true)} isBusy={false} caption="Clear"></WrappedButton>
					<WrappedButton size="small" onClick={() => props.onClose()} isBusy={false} caption="Close"></WrappedButton>
				</div>
				{/* <IconButton
					aria-label="close"
					onClick={() => props.onClose()}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}>
					<CloseIcon />
				</IconButton> */}
			</DialogTitle>
			<DialogContent sx={{ paddingTop: "15px" }}>
				<Grid container>
					{filteredFields
						.sort((x) => x.field_order)
						.map((x, idx) => {
							switch (x.type_code) {
								case "L":
									return (
										<Grid key={idx} item lg={3} md={4} sm={6} xs={12} sx={{ p: 1 }}>
											<Typography variant="subtitle1" display={"block"}>
												{x.field_label}
											</Typography>
											<Checkbox
												onChange={(ev: ChangeEvent) => {
													changeSearchFields(x.field_name, (ev.target as HTMLInputElement).checked);
												}}
												checked={searchFields[x.field_name] || false}
												value={searchFields[x.field_name] || false}
												sx={{ paddingTop: 0, paddingLeft: 0 }}></Checkbox>
										</Grid>
									);
								case "D":
									return (
										<Grid key={idx} item lg={3} md={4} sm={6} xs={12} sx={{ p: 1 }}>
											<LocalizationProvider dateAdapter={AdapterDateFns} sx={{ width: 1 }}>
												<DatePicker
													label={x.field_label}
													onChange={(ev?: any) => {
														changeSearchFields(x.field_name, FormatDataByTypeCode(ev, "D"));
													}}
													value={searchFields[x.field_name] ? new Date(searchFields[x.field_name]) : null}
													renderInput={(params) => <TextField fullWidth={true} sx={{ fontSize: "50px" }} {...params} />}></DatePicker>
											</LocalizationProvider>
										</Grid>
									);
								default:
									return (
										<Grid key={idx} item lg={3} md={4} sm={6} xs={12} sx={{ p: 1 }}>
											<TextField
												className=""
												label={x.field_label}
												sx={{ width: "100%" }}
												InputProps={{
													endAdornment: (
														<InputAdornment position="start">
															<SearchIcon
																sx={{ cursor: "pointer" }}
																onClick={() => {
																	query();
																}}
															/>
														</InputAdornment>
													),
												}}
												onChange={(ev: ChangeEvent) => {
													const newVal = (ev.target as HTMLInputElement).value;
													searchFields[x.field_name] = newVal;
												}}
												onKeyUp={(ev: any) => {
													if (ev.which === 13) {
														query();
													}
												}}></TextField>
										</Grid>
									);
							}
						})}
				</Grid>

				{!load && (
					<TableContainer component={Paper}>
						<Table size="small">
							<TableHead>
								<TableRow>
									{lutInfo?.fields
										.filter((x) => x.is_listed)
										.map((x, idx) => {
											return <TableCell key={idx}>{x.field_label}</TableCell>;
										})}
								</TableRow>
							</TableHead>
							<TableBody>
								{resultSet.map((res, idx) => {
									return (
										<TableRow
											key={idx}
											onClick={() => {
												props.onClose(res);
											}}
											hover
											sx={{ cursor: "pointer" }}>
											{lutInfo?.fields
												.filter((x) => x.is_listed)
												.map((x, idx) => {
													return <TableCell key={idx}>{FormatDataByTypeCode(res[x.field_name], x.type_code)}</TableCell>;
												})}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				)}
				{load && (
					<div style={{ padding: "15px", alignItems: "center", flexDirection: "column", display: "flex" }}>
						<CircularProgress size={40}></CircularProgress>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
}
