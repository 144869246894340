import { Button, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { openForm } from "../Util";
import { AttendanceDetail } from "./AttendanceDetail";
import { AttFields } from "./AttendanceFields";
import { EventDef } from "./AttendanceInAndOut";
import { FormatDataByTypeCode } from "./FormScripts";
import { PlacementDisruption } from "./PlacementDisruption";
export const classes = {
	smallCell: {
		padding: "2px",
		paddingLeft: "4px",
		borderBottom: "none",
	},
} as any;
interface AttClientProps {
	AttData: ClientAttendanceData;
	PdReasons: Array<PlacementDisruption>;
	Refresh: Function;
	GetData: Function;
	validateNextAttendance: Function;
	even: boolean;
}

export interface ClientAttendanceData {
	AttData: Array<AttendanceDetail>;
	billingQs: Array<EventDef>;
}

export default function AttendanceClient(att: AttClientProps) {
	const cliData = att.AttData;
	const fa = cliData.AttData[0];
	const [showQs, setShowQs] = React.useState(false);
	const [qsCounts, setQsCounts] = React.useState([] as Array<Array<any>>);
	const [totalDur, setTotalDur] = React.useState(0);
	const [showAddService, setShowAddService] = React.useState(fa.IsCheckedIn && !fa.IsAbsent && cliData.billingQs.length > 0);

	const toggleAddService = () => {
		setShowAddService(cliData.AttData.filter((x) => x.IsCheckedIn && !x.IsAbsent).length > 0 && cliData.billingQs.length > 0);
	};
	cliData.AttData.forEach((x) => {
		x.UpdateAddServiceButton = toggleAddService;
	});

	const launchQs = async (qs: EventDef) => {
		setShowQs(false);
		openForm(qs.FormID, "NEW", "ADD", qs.EventID, fa.ProgramID, fa.GroupProfileID, fa.PeopleID);
	};

	const fu = () => {
		let diff = cliData.AttData.filter((x) => x.IsCheckedIn)
			.map((x) => {
				const startTime = new Date(FormatDataByTypeCode(new Date(), "D") + " " + FormatDataByTypeCode(x.NewStartTime, "TM")).getTime();
				const endTime: number = x.IsCheckedOut ? (new Date(FormatDataByTypeCode(new Date(), "D") + " " + FormatDataByTypeCode(x.NewCheckOutTime, "TM")).getTime() as number) : new Date().getTime();

				return endTime - startTime;
			})
			.reduce((a, b) => (a += b), 0);

		diff = Math.floor(diff / (1000 * 60));
		setTotalDur(diff);
	};

	const toggleQsVisibility = () => {
		if (cliData.billingQs.length === 1 && !showQs) {
			launchQs(cliData.billingQs[0]);
			return;
		}
		if (!showQs) {
			fetch("Attendance/GetEventCounts", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				cache: "no-cache",
				body: JSON.stringify(fa.PeopleID),
			})
				.then((x) => x.json())
				.then((x) => {
					setQsCounts(x);
					setShowQs(true);
				});
		} else setShowQs(false);

		setShowQs(!showQs);
	};

	const clientPresent = cliData.AttData[0].IsCheckedIn && !cliData.AttData[0].IsAbsent;
	let diff = 0;
	let percent = 0;
	let color = "#e31700";
	let message = "";
	let duration = 0;
	const firstAtt = cliData.AttData[0];

	if (clientPresent) {
		diff = cliData.AttData.filter((x) => x.IsCheckedIn)
			.map((x) => {
				const startTime = new Date(FormatDataByTypeCode(new Date(), "D") + " " + FormatDataByTypeCode(x.NewStartTime, "TM")).getTime();
				const endTime: number = x.IsCheckedOut ? (new Date(FormatDataByTypeCode(new Date(), "D") + " " + FormatDataByTypeCode(x.NewCheckOutTime, "TM")).getTime() as number) : new Date().getTime();

				return endTime - startTime;
			})
			.reduce((a, b) => (a += b), 0);

		diff = Math.floor(diff / (1000 * 60));

		if (diff !== totalDur) {
			setTotalDur(diff);
		}
	}

	if (clientPresent) {
		if (totalDur < firstAtt.HalfDay) {
			percent = (totalDur / firstAtt.FullDay) * 100;
		} else if (totalDur < firstAtt.FullDay) {
			percent = (totalDur / firstAtt.FullDay) * 100;
		} else percent = 100;

		duration = totalDur;
		if (totalDur < firstAtt.HalfDay) {
			message = "You are " + (firstAtt.HalfDay - totalDur > 60 ? FormatDataByTypeCode(firstAtt.HalfDay - totalDur, "TD") : firstAtt.HalfDay - totalDur + " minutes") + " away from a half day.";
		} else if (totalDur >= firstAtt.FullDayThreshold && totalDur < firstAtt.FullDay) {
			message = "You are " + (firstAtt.FullDay - totalDur > 60 ? FormatDataByTypeCode(firstAtt.FullDay - totalDur, "TD") : firstAtt.FullDay - totalDur + " minutes") + " minutes away from a full day.";
			color = "#ff9819";
		} else if (totalDur >= firstAtt.HalfDay && totalDur < firstAtt.FullDay) {
			color = "#ff9819";
		} else {
			color = "#03d100";
			if (firstAtt.IsCheckedOut) message = "You completed a full day.";
		}
	}

	return (
		<>
			{cliData.AttData.map((x: AttendanceDetail, idx: number) => {
				return (
					<TableRow sx={{ backgroundColor: att.even ? "#F2f2f2" : "" }} key={x.AttendanceDetailsID || x.ID}>
						<TableCell style={classes.smallCell} sx={{ paddingLeft: "4px" }}>
							{idx === 0 && x.Client}
							{((idx === 1 && fa.IsCheckedIn && !fa.IsAbsent) || (idx === 0 && cliData.AttData.length === 1 && fa.IsCheckedIn && !fa.IsAbsent)) && showAddService && (
								<div>
									<Button style={{ padding: "4px 6px", minWidth: "100px" }} onClick={toggleQsVisibility} variant="contained" color="primary">
										Add Service
									</Button>
								</div>
							)}
						</TableCell>
						<AttFields GetData={att.GetData} Refresh={att.Refresh} updateParent={fu} att={x} PdReasons={att.PdReasons} validateEndTime={att.validateNextAttendance}></AttFields>
					</TableRow>
				);
			})}
			{showQs &&
				cliData.billingQs.map((x) => {
					const pv = qsCounts?.find((y) => y[1] === x.EventID.toLowerCase());
					return (
						<TableRow>
							<TableCell colSpan={5}>
								<Typography style={{ cursor: "pointer" }} onClick={() => launchQs(x)} variant="body1">
									{x.EventName} ({pv ? pv[0] : 0})
								</Typography>
							</TableCell>
						</TableRow>
					);
				})}
			{fa.IsCheckedIn && !fa.IsAbsent && (
				<TableRow>
					<TableCell colSpan={5} style={{ padding: "2px 0px" }}>
						{/* <div style={{ float: "left", width: percent + "%", backgroundColor: color }}></div> */}
						<div style={{ width: "100%", minHeight: "20px", background: "linear-gradient(90deg, " + color + " " + percent + "%, #fafafa " + percent + "%)", borderRadius: "3px", textAlign: "center", padding: "2px" }}>
							<div style={{ float: "right" }}>{duration > 0 && FormatDataByTypeCode(duration, "TD")}</div>
							{message}
						</div>
					</TableCell>
				</TableRow>
			)}
		</>
	);
}
