import * as React from "react";
import "./NavMenu.css";
import { Dialog, DialogTitle, DialogContent, Button, Typography, DialogActions, FormControlLabel, Switch, Divider } from "@mui/material";
import { getLocalSetting, setLocalSetting, SettingsEnum } from "../Util";
import { parseBoolean } from "./FormScripts";

const classes = {
	formControl: {
		width: "100%",
	},
	settingsLabel: {
		alignItems: "flex-start",
		marginLeft: "2px",
	},
	groupHeader: {
		marginBottom: "8px",
	},
} as any;

interface Props {
	hideDialog: Function;
	isVisible: boolean;
}

export default function SettingsDialog(props: Props) {
	const [currentCheckIn, setCurrentCheckIn] = React.useState(parseBoolean(getLocalSetting(SettingsEnum.CheckInDefaultCurrentTime)));
	const [currentCheckOut, setCurrentCheckOut] = React.useState(parseBoolean(getLocalSetting(SettingsEnum.CheckOutDefaultCurrentTime)));
	return (
		<Dialog onClose={() => props.hideDialog()} aria-labelledby="customized-dialog-title" open={props.isVisible}>
			<DialogTitle id="customized-dialog-title">Settings</DialogTitle>
			<DialogContent dividers>
				<>
					<Typography sx={classes.groupHeader} variant="h6">
						Attendance Settings
					</Typography>
					<Divider />
					<FormControlLabel
						label="Current Time for Check-In Time?"
						sx={classes.settingsLabel}
						control={
							<Switch
								color="primary"
								checked={currentCheckIn}
								onChange={(event: React.ChangeEvent<HTMLInputElement>, val: boolean) => {
									setLocalSetting(SettingsEnum.CheckInDefaultCurrentTime, val.toString());
									setCurrentCheckIn(val);
								}}
							/>
						}
						labelPlacement="top"></FormControlLabel>
					<FormControlLabel
						label="Current Time for Check-Out Time?"
						sx={classes.settingsLabel}
						control={
							<Switch
								color="primary"
								checked={currentCheckOut}
								onChange={(event: React.ChangeEvent<HTMLInputElement>, val: boolean) => {
									setLocalSetting(SettingsEnum.CheckOutDefaultCurrentTime, val.toString());
									setCurrentCheckOut(val);
								}}
							/>
						}
						labelPlacement="top"></FormControlLabel>
					<FormControlLabel
						label="Auto-Save Attendance?"
						sx={classes.settingsLabel}
						control={
							<Switch
								color="primary"
								checked={parseBoolean(getLocalSetting(SettingsEnum.AutoSaveAttendance))}
								onChange={(event: React.ChangeEvent<HTMLInputElement>, val: boolean) => {
									if (!window.confirm("Changing this setting requires reloading the app, continue?")) return;
									setLocalSetting(SettingsEnum.AutoSaveAttendance, val.toString());
									document.location.reload();
								}}
							/>
						}
						labelPlacement="top"></FormControlLabel>
				</>
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={() => props.hideDialog()} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
