import * as React from "react";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FacilityContext } from "../App";
import { createForm, createFormLine, getLocalSetting, isNullOrUndefined, saveManyForm, SettingsEnum } from "../Util";

import { AttendanceDetail } from "./AttendanceDetail";

import { PlacementDisruption } from "./PlacementDisruption";
import AttendanceClient, { ClientAttendanceData } from "./AttendanceClient";
import WrappedButton from "./WrappedButton";
import { FormatDataByTypeCode, parseBoolean, cloneObject } from "./FormScripts";

const classes = {
	wrapper: {
		display: "flex",
		flexDirection: "column",
		position: "relative",
		height: "calc(100% - 32px)",
	},
	progressWrapper: {
		position: "absolute",
		top: "0",
		right: "0",
		left: "0",
		bottom: "0",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 50,
	},
	iconWrapper: {
		backgroundColor: "white",
		padding: "4px",
		fontSize: "24px",
		width: "40px",
		height: "40px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	upperPane: {
		display: "flex",
		alignItems: "center",
	},
	upperPaneButton: {
		flex: 1,
		margin: "4px",
		whiteSpace: "nowrap",
	},
	attendancePane: {
		flex: 1,
		overflowY: "auto",
	},
	smallCell: {
		padding: "2px",
	},
	bottomPane: {
		backgroundColor: "#ff9819",
		fontWeight: 600,
		textAlign: "center",
		borderBottomRightRadius: "4px",
		borderBottomLeftRadius: "4px",
	},
} as any;

export interface EventDef {
	EventID: string;
	FormID: string;
	EventName: string;
}

export function getAttendanceSaveForm(att: AttendanceDetail) {
	let isNewForm: boolean = isNullOrUndefined(att.AttendanceDetailsID);

	let form: any = createForm(att.formMode, isNewForm ? "NEW" : (att.AttendanceDetailsID as string), "attendance_details");

	if (isNewForm) {
		form.FormLines.push(createFormLine("people_id", att.PeopleID));
		form.FormLines.push(createFormLine("event_log_id", att.EnrollmentID));
		form.FormLines.push(createFormLine("staff_id", att.StaffID));
		form.FormLines.push(createFormLine("schedules_id", att.SchedulesID));
		form.FormLines.push(createFormLine("group_profile_id", att.GroupProfileID));
		let serviceDate: Date = new Date(FormatDataByTypeCode(new Date(), "D") + " " + FormatDataByTypeCode(att.NewStartTime, "TM"));
		form.FormLines.push(createFormLine("service_date", FormatDataByTypeCode(serviceDate, "DT"), "DT"));

		if (att.IsCheckedOut) {
			let toClient = att.IsCheckedOut ? new Date("1/1/1900 " + FormatDataByTypeCode(att.NewCheckOutTime, "TM")) : null;
			form.FormLines.push(createFormLine("time_to_client", FormatDataByTypeCode(toClient, "DT"), "TM"));
		}
	} else {
		let toClient = att.IsCheckedOut ? new Date("1/1/1900 " + FormatDataByTypeCode(att.NewCheckOutTime, "TM")) : null;
		form.FormLines.push(createFormLine("time_to_client", FormatDataByTypeCode(toClient, "DT"), "TM"));
		let duration = null;
		if (toClient !== null) {
			duration = (toClient.getTime() - att.StartTime.getTime()) / (1000 * 60);
		}
		form.FormLines.push(createFormLine("duration_client", duration?.toString(), "TD"));
	}

	if (form.formMode === "DELETE" && !window.confirm("Are you sure you want to delete?")) return;

	if (!att.IsAbsent) {
		let fromClient: Date = new Date("1/1/1900 " + FormatDataByTypeCode(att.NewStartTime, "TM"));
		form.FormLines.push(createFormLine("time_from_client", FormatDataByTypeCode(fromClient, "DT"), "TM"));
	} else {
		form.FormLines.push(createFormLine("la_definition_id", att.AbsentReason));
		form.FormLines.push(createFormLine("time_from_client", FormatDataByTypeCode(att.CheckOutTime, "DT"), "TM"));
	}

	return form;
}

export function AttendanceInAndOut() {
	let currentFacility = React.useContext(FacilityContext);
	let [attendanceData, setAttendanceData] = React.useState([] as Array<ClientAttendanceData>);

	let [pdReasons, setPdReasons] = React.useState([] as Array<PlacementDisruption>);

	const [showSearch, setShowSearch] = React.useState(false);
	const [searchString, setSearchString] = React.useState("");
	const [enableButtons, setEnableButtons] = React.useState(false);
	const autoSave = parseBoolean(getLocalSetting(SettingsEnum.AutoSaveAttendance));

	const [isSaving, setIsSaving] = React.useState(false);
	const [pendingCheckout, setPendingCheckout] = React.useState(0);

	const [load, setLoad] = React.useState(false);

	const getPdReasons = () => {
		if (pdReasons.length === 0) {
			fetch("Attendance/PlacementDisruption")
				.then((dt) => dt.json())
				.then((dataSet: any) => {
					let details: Array<PlacementDisruption> = [] as Array<PlacementDisruption>;
					dataSet.forEach((x: any) => {
						details.push({
							EventName: x.event_name,
							EventDefinitionID: x.event_definition_id,
						});
					});
					setPdReasons(details);
				});
		}
	};

	const refresh = () => {
		setPendingCheckout(getAttendanceData().filter((x) => x.AttData.some((x) => x.IsCheckedIn && !x.IsAbsent && !x.IsCheckedOut)).length);
		return toggleButtons();
	};

	React.useEffect(() => {
		if (!isNullOrUndefined(currentFacility)) {
			getData();
			getPdReasons();
		}
		// eslint-disable-next-line
	}, [currentFacility]);
	const getData = () => {
		setPendingCheckout(0);
		setLoad(true);
		fetch("Attendance/GetQSForAttendance")
			.then((dt) => dt.json())
			.then((dataSet: any) => {
				dataSet.splice(0, 1);
				let details: Array<EventDef> = dataSet.map((x: any) => {
					return {
						EventName: x[1],
						EventID: x[0],
						FormID: x[2],
					} as EventDef;
				});
				details = details.sort((a, b) => a.EventName.localeCompare(b.EventName));

				fetch("Attendance/Attendance")
					.then((dt) => dt.json())
					.catch(() => {
						setLoad(false);
					})
					.then((dataSet: any) => {
						const clientMapping = {} as any;
						dataSet.forEach((x: any) => {
							const newAtt = new AttendanceDetail(x);

							if (typeof clientMapping[newAtt.PeopleID] === "undefined") {
								clientMapping[newAtt.PeopleID] = [];
							}
							clientMapping[newAtt.PeopleID].push(newAtt);

							if (newAtt.IsCheckedOut && !newAtt.IsAbsent && newAtt.CanAddAtt) {
								const newX = cloneObject(x);
								newX.attendance_details_id = null;
								newX.is_checked_out = false;
								newX.time_from = x.time_to;
								newX.time_to = newX.default_time_to;
								const att2 = new AttendanceDetail(newX);
								att2.IsAdditionalAttendance = true;
								att2.CanAddAtt = false;
								clientMapping[newAtt.PeopleID].push(att2);
							}
						});
						const d = Object.keys(clientMapping).map((x) => {
							return {
								AttData: clientMapping[x], //
								billingQs: details,
							} as ClientAttendanceData;
						});
						setAttendanceData(d);
						setPendingCheckout(d.filter((x) => x.AttData.some((x) => x.IsCheckedIn && !x.IsAbsent && !x.IsCheckedOut)).length);
						setLoad(false);
					});
			});

		setEnableButtons(false);
	};

	const getAttendanceData = () => {
		let att = attendanceData.filter((x) => x.AttData[0].Client.toLowerCase().includes(searchString.toLowerCase()));
		return att;
	};

	const toggleButtons = () => {
		const oldEnable = enableButtons;
		const shouldEnable = getRowsToSave().length > 0;
		setEnableButtons(shouldEnable);

		return (oldEnable && !shouldEnable) || (shouldEnable && !oldEnable);
	};

	const getRowsToSave = () => {
		return [].concat.apply([], [
			...getAttendanceData().map((x) => {
				return x.AttData.filter((x) => x.IsAbsent !== x.OldIsAbsent || x.AbsentReason !== x.OldAbsentReason || (x.IsCheckedIn && isNullOrUndefined(x.AttendanceDetailsID)) || (!x.IsCheckedIn && !isNullOrUndefined(x.AttendanceDetailsID)) || x.StartTime !== x.NewStartTime || x.IsCheckedOut !== x.OldCheckOut || x.CheckOutTime !== x.NewCheckOutTime);
			}),
		] as any);
	};

	const save = async () => {
		setIsSaving(true);
		const rowsToSave = getRowsToSave();
		let formsToSave: Array<any> = [] as Array<any>;

		rowsToSave.forEach((att) => {
			const form = getAttendanceSaveForm(att);

			formsToSave.push(form);
		});
		await saveManyForm(formsToSave);
		await getData();
		setEnableButtons(false);
		setIsSaving(false);
	};

	const validateNextAttendance = (startDate: Date, endDate?: Date, att?: AttendanceDetail) => {
		const dt = getAttendanceData().find((x) => x.AttData[0].PeopleID === att?.PeopleID);

		return (
			dt?.AttData.filter((x) => {
				return x.AttendanceDetailsID !== att?.AttendanceDetailsID && x.StartTime.getTime() < (endDate?.getTime() ?? 999999999999999) && (x.CheckOutTime?.getTime() ?? 999999999999999) > startDate.getTime();
			}).length === 0
		);
	};

	const renderGrid = () => {
		let ad = getAttendanceData();

		return (
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell sx={classes.smallCell}>Client</TableCell>
							<TableCell sx={classes.smallCell} style={{ width: "5px" }}>
								{/* <Checkbox
									onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
										toggleAll(checked, true);
									}}
									style={{ padding: "9px 0px" }}></Checkbox> */}
							</TableCell>
							<TableCell sx={classes.smallCell}>In-Time</TableCell>
							<TableCell sx={classes.smallCell}>{!getAttendanceData().some((x) => x.AttData.some((x) => x.IsCheckedIn)) ? "" : "Out-Time"}</TableCell>
							<TableCell sx={classes.smallCell} style={{ width: "5px", paddingRight: "2px" }}></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ad.map((att: ClientAttendanceData, idx: number) => {
							return <AttendanceClient validateNextAttendance={validateNextAttendance} even={idx % 2 === 0} Refresh={refresh} GetData={getData} key={idx} PdReasons={pdReasons} AttData={att}></AttendanceClient>;
						})}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	return (
		<FacilityContext.Consumer>
			{(facility: string) => {
				var controls = !isNullOrUndefined(facility) ? (
					<>
						{load && (
							<div style={{ padding: "15px", alignItems: "center", flexDirection: "column", display: "flex" }}>
								<CircularProgress size={40}></CircularProgress>
							</div>
						)}
						{!load && (
							<div style={classes.wrapper}>
								<div style={classes.upperPane}>
									{!showSearch && !autoSave && (
										<>
											<WrappedButton isBusy={isSaving} isDisabled={!enableButtons} sx={classes.upperPaneButton} onClick={() => save()} caption="Save"></WrappedButton>
											<Button disabled={!enableButtons} sx={classes.upperPaneButton} onClick={() => getData()} variant="contained" color="primary">
												Cancel
											</Button>
										</>
									)}
									{<TextField hidden={!showSearch && !autoSave} style={{ margin: "4px" }} label="Search Clients" sx={classes.upperPaneButton} size="small" onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchString(event.target.value)} variant="outlined"></TextField>}
									{!autoSave && (
										<FontAwesomeIcon
											style={{ fontSize: "24px" }}
											onClick={() => {
												setShowSearch(!showSearch);
												setSearchString("");
											}}
											icon={["fas", "magnifying-glass"]}></FontAwesomeIcon>
									)}
								</div>
								<div style={classes.attendancePane}>{renderGrid()}</div>
								<div style={classes.bottomPane}>{pendingCheckout > 0 ? pendingCheckout + " client" + (pendingCheckout > 1 ? "s" : "") + " pending checkout" : ""}</div>
							</div>
						)}
					</>
				) : (
					<Typography variant="h5">Select a Facility.</Typography>
				);
				return controls;
			}}
		</FacilityContext.Consumer>
	);
}
